<template>
<div class="notice">
        <div class="cont">
          <van-row class="top" @click="getInfo">
            <h3>公告通知</h3>
          </van-row>
          <van-row class="top mail">
            <h4>人大代表工作室</h4>
            <van-row class="up">
              <van-col :span="12" class="office" v-for="(item, index) in dataList" :key="index" @click="goPeopleInfo(item.id)">
                <van-row>
                  <van-image :src="item.img" :error-icon="item.sex === 1? man : woman"></van-image>
                </van-row>
                <van-row>
                  <p>{{item.name}}</p>
                  <h5>{{item.name}}人大代表工作室</h5>
                </van-row>
              </van-col>
            </van-row>
          </van-row>
        </div>
  </div>
</template>

<script>
import {getImageStream} from "../../utils";
export default {
  components :{
    getImageStream
  },
     data() {
        return {
          searchValue: '',
          loading: false,
          finished: false,
          limit: 10,
          page: 0,
          dataList: [],
          man: require('@/assets/img/weMan.png'),
          woman: require('@/assets/img/weWoman.png')
        };
     },
    methods: {
        getInfo () {
          this.$router.push({path: '/sideNotice'})
        },
      goPeopleInfo (id) {
        this.$router.push({path: '/peopleStudio', query:{id: id}})
      },
      getPeople () {
        this.$http({
          url: this.$http.adornUrl('/wxapp/app/userinfoauxiliary/getNpcList'),
          method: 'post',
          params: this.$http.adornParams({
            orgId: this.$orgId
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.list
            this.dataList.map(item => {
              item['img'] = getImageStream(item.headImg || 'aaa')
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      }
    },
    created () {
       this.getPeople()
    }
}
</script>

<style lang="scss" scoped>
.notice {
    //padding-top: 100px;
    .cont {
        //padding: 20px;
        background-color: #f5f5f5;
        .top {
            position: relative;
            height: 160px;
            background-image: url("../../assets/img/weSide_bg1.png");
            background-size: 100%;
            background-repeat: no-repeat;
          h3 {
            font-size: 40px;
            font-weight: 400;
            font-family: PingFang-SC-Bold;
            color: #fff;
            padding-top: 57px;
            padding-left: 38%;
          }
          h4 {
            font-size: 40px;
            font-weight: 500;
            font-family: PingFang-SC-Bold;
            color: #fff;
            margin: 0;
            padding: 50px 0 27px 30%;
          }
          span {
            position: absolute;
            width: 48px;
            height: 7px;
            background: #FFFFFF;
            opacity: 0.5;
            border-radius: 4px;
            margin: 55px 0 0 48px;
          }
        }
      .mail {
        min-height: 100vh;
        margin-top: 20px;
        background-image: url("../../assets/img/weSide_bg2.png");
        .up {
          margin-left: 10px;
          margin-bottom: 20px;
        }
        .office {
          width: 290px;
          height: 310px;
          margin-top: 20px;
          margin-left: 40px;
          background: #FFFFFF;
          box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          .van-row:nth-child(1) {
            ::v-deep .van-image__img {
              display: block;
              width: 104px;
              height: 104px;
              border-radius: 50%;
              margin: 30px 95px 0;
            }
            ::v-deep .van-image {
              position: unset;
              display: inline;
            }
            ::v-deep .van-image__error, .van-image__loading {
              position: unset;
              top: 0;
              left: 0;
            }
            ::v-deep .van-icon__image {
              width: 104px;
              height: 104px;
              margin: 30px 95px 0;
            }
          }
          .van-row:nth-child(2) {
            p {
              font-size: 30px;
              font-weight: bold;
              color: #333333;
              font-family: PingFang SC;
              text-align: center;
            }
            h5 {
              font-size: 24px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #999999;
              margin: 0;
              text-align: center;
            }
          }
        }
      }
    }
}
</style>
